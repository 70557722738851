export const heading = {
    title: {
        "name": "Rahul Kumar",
        "designation": "Architect, MLOps Engineer"
    },
    company: {
        designation: "Architect, Tech Delivery",
        company: "QuantumBlack, AI by McKinsey"
    },
    education: {
        degree: "Computer Science & Engineering (B.E.)",
        college: "BMS College of Engineering"
    }
}

export const experience = [
    {
        type: "designation",
        start_date: new Date(2022, 10, 1),
        end_date: null,
        org: {
            title: "QuantumBlack, AI by McKinsey",
            icon: "img/org/qb-white.png"
        },
        title: "Architect",
        summary: "Worked on analytics projects"
    },
    {
        type: "designation",
        start_date: new Date(2021, 4, 1),
        end_date: new Date(2022, 9, 30),
        org: {
            title: "QuantumBlack, AI by McKinsey",
            icon: "img/org/qb-white.png"
        },
        title: "Senior Digital Analyst",
        summary: "Worked on analytics projects"
    },
    {
        type: "project",
        date: new Date(2022, 4, 1),
        title: "MultiCloud MLOps Platform",
        goal: "Developed a highly scalable multi-cloud analytics platform for developing and deploying machine learning projects.",
        personal_contribution: [
            "Used AWS and Tencent cloud, the solution was deployed across multiple regions for different teams across the world.",
            "Created CI/CD pipeline for infrastructure setup using GitHub actions and ArgoCD and IaaC using Terraform.",
            "Implemented Spark on Kubernetes to take advantage of the distributed computing power of k8s and spark.",
            "Deployed self-hosted GitHub action runners on the k8s cluster. Implemented autoscaling and monitoring for runners.",
            "Created GitHub Action for deploying analytics projects on our platform.",
        ],
        tech_stack: ["K8s", "Amazon Web Services (AWS)", "Tencent Cloud", "GitHub Actions", "Terraform", "ArgoCD", "ArgoWorkflow", "Grafana", "Prometheus", "Python", "TypeScript", "Spark", "FastAPI"],
        org: {
            title: "QuantumBlack, AI by McKinsey",
            icon: "img/org/qb-white.png"
        }
    },
    {
        type: "project",
        date: new Date(2022, 1, 1),
        title: "Architected and deployed analytics solution on AWS",
        goal: "Worked with Europe automotive client to optimize incentives for different models and channels.",
        personal_contribution: [
            "Designed end-to-end architecture for deploying analytics solutions (DE, DS and FE App) on AWS cloud.",
            "Implemented CI/CD using GitHub Actions and IaaC using Terraform.",
            "Implemented MLOps best practices to automate fully automated E2E ML solutions.",
            "Integrated the application with the client’s network and auth server.",
            "Build data engineering pipeline for incentive optimization use case.",
        ],
        tech_stack: ["Amazon Web Services (AWS)", "GitHub Actions", "Terraform", "Python", "Kedro", "MLflow"],
        org: {
            title: "QuantumBlack, AI by McKinsey",
            icon: "img/org/qb-white.png"
        }
    },
    {
        type: "project",
        date: new Date(2021, 1, 1),
        title: "Metal client in SE Asia",
        goal: "Worked on predicting steel price based on micro and macro economics data",
        personal_contribution: [
            "Build end to end DE pipeline using internal and external data sources.",
            "Optimized DS pipeline by using multiprocessing approach to decrease execution time to 1/3rd.",
            "Setup multi node Jenkins agents for CI/CD build jobs and DE/DS pipeline orchestration jobs using on on-premises infrastructure.",

        ],
        tech_stack: ["Python", "Kedro", "Jenkins", "Docker"],
        org: {
            title: "QuantumBlack, AI by McKinsey",
            icon: "img/org/qb-white.png"
        }
    },
    {
        type: "designation",
        start_date: new Date(2021, 4, 1),
        end_date: new Date(2021, 5, 30),
        org: {
            title: "McKinsey Digital Labs",
            icon: "img/org/mck-white.jpeg"
        },
        title: "Senior Digital Analyst"
    },
    {
        type: "project",
        date: new Date(2021, 1, 1),
        title: "In house data annotation tool",
        goal: "Worked with one of the largest telecom provider in SE Asia and helped in developing data platform and usecases like credit scoring, conversion of users to 4G, revenue increase etc.",
        personal_contribution: [
            "Tool to improve the quality of data by 'active learning' to data cleaning & structuring.",
            "It combines ML with humans-in-the-loop to allow large amounts of data to be cleaned and structured largely by an automatic 'parser' but with human 'curators' overseeing and correcting.",
            "Client can plug different types of ML model or even build his own custom model."
        ],
        tech_stack: ["Python", "FastAPI", "PostgresSQL", "RabbitMQ"],
        org: {
            title: "McKinsey Digital Labs",
            icon: "img/org/mck-white.jpeg"
        }
    },
    {
        type: "designation",
        start_date: new Date(2018, 8, 1),
        end_date: new Date(2021, 4, 31),
        org: {
            title: "McKinsey Digital Labs",
            icon: "img/org/mck-white.jpeg"
        },
        title: "Digital Analyst"
    },
    {
        type: "project",
        date: new Date(2021, 1, 1),
        title: "Petabyte Scale Analytics project for Telecom client SE Asia",
        goal: "Worked with one of the largest telecom provider in SE Asia and helped in developing data platform and usecases like credit scoring, conversion of users to 4G, revenue increase etc.",
        personal_contribution: [
            "Built big data pipelines which process TBs of data every day.",
            "Automated feature development using configuration, it drastically reduced the feature development time.",
            "Developed a framework for continuously measuring the data quality in multiple dimensions and alerting the team if there is any delay or decrease in data quality.",
            "Optimized spark pipelines using multithreading python pipeline orchestration, parallelizing independent spark jobs, improving partitioning and task size etc. These optimizations reduced the training time of the analytics pipeline by more than 50%.",
        ],
        tech_stack: ["Python", "PySpark", "Kedro", "Hadoop", "Hive"],
        org: {
            title: "QuantumBlack, AI by McKinsey",
            icon: "img/org/qb-white.png"
        }
    },
    {
        type: "designation",
        start_date: new Date(2018, 1, 6),
        end_date: new Date(2018, 7, 31),
        org: {
            title: "McKinsey Digital Labs",
            icon: "img/org/mck-white.jpeg"
        },
        title: "Intern"
    },
    {
        type: "project",
        date: new Date(2018, 5, 1),
        title: "Banking client in SE Asia",
        goal: "Developed onboarding and loan platform for a banking client.",
        personal_contribution: [
            "Developed FE application for onboarding platform for payroll.",
            "Implemented image compression microservice for managing scanned documents.",
            "Used Azure Logic App for creating workflow for onboarding and document processing."
        ],
        tech_stack: ["NodeJS", "ReactJS", "Microsoft Azure"],
        org: {
            title: "McKinsey Digital Labs",
            icon: "img/org/mck-white.jpeg"
        },
    }
]
