import React, { Fragment } from 'react';
import { InferProps } from "prop-types";

import { Header } from './Header/Header';
import { Body } from './Body/Body';

import './Homepage.css'

export function Homepage(props: InferProps<typeof Homepage.propTypes>) {

    React.useEffect(() => {
        document.title = "Rahul Kumar"
    }, []);

    return (
        <Fragment>
            <Header />
            <Body />
        </Fragment>
    )
}

Homepage.propTypes = {}
