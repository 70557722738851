import React from 'react';
import { InferProps } from "prop-types";

import { Link, Tooltip } from '@mui/material';

import ContactPageIcon from '@mui/icons-material/ContactPage';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

import './Header.css'


export function Header(props: InferProps<typeof Header.propTypes>) {
    return (
        <div className="header-desktop">
            <div className="header-desktop-icon">
                <Tooltip title="Resume">
                    <Link href="resume/Rahul_Kumar_Resume.pdf" target="_blank">
                        <ContactPageIcon />
                    </Link>
                </Tooltip>
            </div>
            <div className="header-desktop-icon">
                <Tooltip title="LinkedIn">
                    <Link href="https://www.linkedin.com/in/rahulkumar1707" target="_blank">
                        <LinkedInIcon />
                    </Link>
                </Tooltip>
            </div>
            <div className="header-desktop-icon">
                <Tooltip title="GitHub">
                    <Link href="https://github.com/debugger24" target="_blank">
                        <GitHubIcon />
                    </Link>
                </Tooltip>
            </div>
        </div>
    )
}

Header.propTypes = {}
