import React from 'react';
import PropTypes, { InferProps } from "prop-types";

import { Button, Chip, Collapse } from '@mui/material';
import {
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot
} from '@mui/lab/';

import './Project.css'

export function Project(props: InferProps<typeof Project.propTypes>) {
    const [isMoreDetailsOpen, setIsMoreDetailsOpen] = React.useState(false);

    const setIsMoreDetailsToOpen = () => {
        setIsMoreDetailsOpen(true);
    }

    const setIsMoreDetailsToClose = () => {
        setIsMoreDetailsOpen(false);
    }

    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot color="success" />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <div className='body'>
                    <div className='primary'>
                        <h3>{props.title}</h3>
                        <p>{props.goal}</p>
                    </div>
                    <Collapse in={isMoreDetailsOpen}>
                        <div className='personal-contribution'>
                            <strong>Personal Contribution</strong>
                            <ul>
                                {
                                    props.personal_contribution.map(contribution => (
                                        <li>{contribution}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className='tech-stack'>
                            {
                                props.tech_stack.map(item => (
                                    <Chip
                                        label={item}
                                        variant="filled"
                                        size={"small"}
                                        color={"primary"}
                                    />
                                ))
                            }
                        </div>
                    </Collapse>
                    {/* <div className='org'>
                        <Chip
                            variant={"outlined"}
                            avatar={
                                <Avatar
                                    alt={"QB"}
                                    src={props.org.icon}
                                />
                            }
                            label={props.org.title}
                        />
                    </div> */}
                    {!isMoreDetailsOpen && <Button onClick={setIsMoreDetailsToOpen}>Read More</Button>}
                    {isMoreDetailsOpen && <Button onClick={setIsMoreDetailsToClose}>Close</Button>}
                </div>
            </TimelineContent>
        </TimelineItem>
    )
}

Project.propTypes = {
    title: PropTypes.string.isRequired,
    goal: PropTypes.string.isRequired,
    personal_contribution: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    tech_stack: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    org: PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    }).isRequired
}
