import React from 'react';
import { InferProps } from "prop-types";
import { Typography, Button } from '@mui/material';

import './Certification.css'

const CertificateLevel = {
    Advanced: "Advanced",
    Associate: "Associate",
    Foundation: "Foundation"
}

const certificates = [
    // Important
    {
        title: "Kubestronaut",
        issued_by: "The Linux Foundation",
        verification_link: "https://www.credly.com/badges/a347a02b-2ee9-4f30-8fbc-747538e23c85/public_url",
        level: CertificateLevel.Advanced,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: false,
        important: true,
        badge: "img/cert/kubestronaut.png"
    },
    {
        title: "AWS Certified Solutions Architect – Professional",
        issued_by: "Amazon Web Services (AWS)",
        verification_link: "https://www.credly.com/badges/df843aa1-ab0a-4997-9a11-0364c6a06404/public_url",
        level: CertificateLevel.Advanced,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: false,
        important: true,
        badge: "img/cert/aws-certified-solutions-architect-professional.png"
    },
    {
        title: "AWS Certified Machine Learning – Specialty",
        issued_by: "Amazon Web Services (AWS)",
        verification_link: "https://www.credly.com/badges/7231b771-f1f0-4497-ac21-d069d006126e/public_url",
        level: CertificateLevel.Advanced,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: false,
        important: true,
        badge: "img/cert/aws-certified-machine-learning-specialty.png",
    },
    {
        title: "AWS Certified Security – Specialty",
        issued_by: "Amazon Web Services (AWS)",
        verification_link: "https://www.credly.com/badges/23d19728-d075-4952-b505-ad673f369073/public_url",
        level: CertificateLevel.Advanced,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: false,
        important: true,
        badge: "img/cert/aws-certified-security-specialty.png"
    },
    {
        title: "CKAD: Certified Kubernetes Application Developer",
        issued_by: "The Linux Foundation",
        verification_link: "https://www.credly.com/badges/dd24c6bc-9f78-48c4-ba15-b74fb4f0577c/public_url",
        level: CertificateLevel.Advanced,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: false,
        important: true,
        badge: "img/cert/ckad-certified-kubernetes-application-developer.png"
    },
    {
        title: "CKA: Certified Kubernetes Administrator",
        issued_by: "The Linux Foundation",
        verification_link: "https://www.credly.com/badges/99f2dec0-068c-422e-82ea-c8ddcfcdb39a/public_url",
        level: CertificateLevel.Advanced,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: false,
        important: true,
        badge: "img/cert/cka-certified-kubernetes-administrator.png"
    },
    {
        title: "CKS: Certified Kubernetes Security Specialist",
        issued_by: "The Linux Foundation",
        verification_link: "https://www.credly.com/badges/d88337c5-6ea7-447f-a0ca-c23ef26ea12c/public_url",
        level: CertificateLevel.Advanced,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: false,
        important: true,
        badge: "img/cert/cks-certified-kubernetes-security-specialist.png"
    },
    {
        title: "Microsoft Certified: Azure Data Scientist Associate",
        issued_by: "Microsoft",
        verification_link: "https://www.credly.com/badges/e50e9f0d-8246-43e6-a9e2-cc5faf38330e/public_url",
        level: CertificateLevel.Associate,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: false,
        important: true,
        badge: "img/cert/microsoft-certified-azure-data-scientist-associate.png"
    },
    {
        title: "Microsoft Certified: Azure Data Engineer Associate",
        issued_by: "Microsoft",
        verification_link: "https://www.credly.com/badges/8f78df14-67e8-460a-a50f-5469ac6022ff/public_url",
        level: CertificateLevel.Associate,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: true,
        important: true,
        badge: "img/cert/microsoft-certified-azure-data-engineer-associate.png"
    },
    // Not Important
    {
        title: "AWS Certified Developer – Associate",
        issued_by: "Amazon Web Services (AWS)",
        verification_link: "https://www.credly.com/badges/fb88c6a0-a590-473c-b620-49ba665d01c2/public_url",
        level: CertificateLevel.Associate,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: false,
        important: false,
        badge: "img/cert/aws-certified-developer-associate.png"
    },
    {
        title: "AWS Certified Data Analytics – Specialty",
        issued_by: "Amazon Web Services (AWS)",
        verification_link: "https://www.credly.com/badges/a9721a2a-4611-4c67-a185-757b97fa51fb/public_url",
        level: CertificateLevel.Advanced,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: false,
        important: false,
        badge: "img/cert/aws-certified-data-analytics-specialty.png"
    },
    {
        title: "AWS Certified Solutions Architect – Associate",
        issued_by: "Amazon Web Services (AWS)",
        verification_link: "https://www.credly.com/badges/7a7bec6a-8ebf-49b0-b2e2-1ae2f6aa3f0e/public_url",
        level: CertificateLevel.Associate,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: false,
        important: false,
        badge: "img/cert/aws-certified-solutions-architect-associate.png"
    },
    {
        title: "Astronomer Certification for Apache Airflow Fundamentals",
        issued_by: "",
        verification_link: "https://www.credly.com/badges/63ae75cd-42c4-44d0-8564-5faa3379a749/public_url",
        level: CertificateLevel.Foundation,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: true,
        important: false,
        badge: "img/cert/astronomer-certification-for-apache-airflow-fundamentals.png"
    },
    {
        title: "HashiCorp Certified: Vault Associate",
        issued_by: "HashiCorp",
        verification_link: "https://www.credly.com/badges/aa895f83-4782-455f-8b3e-e7ab1886254e/public_url",
        level: CertificateLevel.Associate,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: true,
        important: false,
        badge: "img/cert/hashicorp-certified-vault-associate-002.png"
    },
    {
        title: "HashiCorp Certified: Terraform Associate",
        issued_by: "HashiCorp",
        verification_link: "https://www.credly.com/badges/e5273fbf-037c-4715-947f-c4da48181955/public_url",
        level: CertificateLevel.Associate,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: false,
        important: false,
        badge: "img/cert/hashicorp-certified-terraform-associate-002.png"
    },
    {
        title: "DP-200 Implementing an Azure Data Solution",
        issued_by: "Microsoft",
        verification_link: "https://www.credly.com/badges/b88f572d-39d9-4b97-b89d-02919560ac11/public_url",
        level: CertificateLevel.Associate,
        start_date: "",
        end_date: "",
        type: "Exam",
        disabled: false,
        hidden: true,
        important: false,
        badge: "img/cert/dp-200-implementing-an-azure-data-solution.1.png"
    },
    {
        title: "DP-201 Designing an Azure Data Solution",
        issued_by: "Microsoft",
        verification_link: "https://www.credly.com/badges/8439cbe1-5d45-4705-bcb4-12a93954412d/public_url",
        level: CertificateLevel.Associate,
        start_date: "",
        end_date: "",
        type: "Exam",
        disabled: false,
        hidden: true,
        important: false,
        badge: "img/cert/dp-201-designing-an-azure-data-solution.1.png"
    },
    {
        title: "Microsoft Certified: Azure Developer Associate",
        issued_by: "Microsoft",
        verification_link: "https://www.credly.com/badges/e9e0c4b9-a179-44b7-954d-0122727ef663/public_url",
        level: CertificateLevel.Associate,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: true,
        important: false,
        badge: "img/cert/microsoft-certified-azure-developer-associate.1.png"
    },
    {
        title: "OpenHack: Modern Data Warehousing",
        issued_by: "Microsoft",
        verification_link: "https://www.credly.com/badges/d2b0e0e6-dc28-4423-b099-ea07b5acbcd1/public_url",
        level: CertificateLevel.Associate,
        start_date: "",
        end_date: "",
        type: "Hackathon",
        disabled: false,
        hidden: true,
        important: false,
        badge: "img/cert/openhack-modern-data-warehousing.png"
    },
    // Hidden
    {
        title: "LFS458: Kubernetes Administration",
        issued_by: "The Linux Foundation",
        verification_link: "https://www.credly.com/badges/6ff29b8f-00ff-4a93-81d7-ec191e3e75e0/public_url",
        level: CertificateLevel.Advanced,
        start_date: "",
        end_date: "",
        type: "Training",
        disabled: false,
        hidden: true,
        important: false,
        badge: "img/cert/lfs458-kubernetes-administration.png"
    },
    {
        title: "LFD459: Kubernetes for App Developers",
        issued_by: "The Linux Foundation",
        verification_link: "https://www.credly.com/badges/ebe85ac5-21cb-4525-86dc-5551c691b82f/public_url",
        level: CertificateLevel.Advanced,
        start_date: "",
        end_date: "",
        type: "Training",
        disabled: false,
        hidden: true,
        important: false,
        badge: "img/cert/lfd459-kubernetes-for-app-developers.png"
    },
    {
        title: "AWS Certified Cloud Practitioner",
        issued_by: "Amazon Web Services (AWS)",
        verification_link: "https://www.credly.com/badges/1023a4dc-199b-4f52-a999-a4118df75a46/public_url",
        level: CertificateLevel.Foundation,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: true,
        important: false,
        badge: "img/cert/aws-certified-cloud-practitioner.png"
    },
    {
        title: "Microsoft Certified: Azure Fundamentals",
        issued_by: "Microsoft",
        verification_link: "https://www.credly.com/badges/038e659f-721a-444e-a3b3-93ce9559ee15/public_url",
        level: CertificateLevel.Foundation,
        start_date: "",
        end_date: "",
        type: "Certification",
        disabled: false,
        hidden: true,
        important: false,
        badge: "img/cert/microsoft-certified-azure-fundamentals.png"
    },

]



// interface Map {
//     [key: string]: "success" | "warning" | "error" | "primary" | "default"
// }

// const color_mapping: Map = {
//     "Advanced": "error",
//     "Associate": "primary",
//     "Foundation": "default",
// }


export function Certification(props: InferProps<typeof Certification.propTypes>) {
    const [showAll, setShowAll] = React.useState(false)
    const [showHidden, setShowHidden] = React.useState(false)

    const handleShowMore = () => {
        if (!showAll) {
            setShowAll(true);
        }
        else if (!showHidden) {
            setShowHidden(true);
        }
    }

    return (
        <div className="certification">
            <Typography variant="h4" component="h2" className="title">
                Certification
            </Typography>
            <div className="certificate-list">
                {
                    certificates
                        .filter(certificate => certificate.disabled === false)
                        .filter(certificate => certificate.hidden === false || showHidden)
                        .filter(certificate => certificate.important === true || showAll)
                        .map(certificate => {
                            return (
                                <div className="certificate">
                                    <img alt={certificate.title} src={certificate.badge} className="badge" />
                                    <div className="info">
                                        <h3 className="title">{certificate.title}</h3>
                                        {/* <Chip
                                        label={certificate.level}
                                        variant="filled"
                                        size={"small"}
                                        color={color_mapping[certificate.level]}
                                    /> */}
                                    </div>
                                </div>
                            );
                        })

                }
            </div>
            {showHidden === false && <Button onClick={handleShowMore} variant="text">Show more</Button>}
        </div>
    )
}

Certification.propTypes = {}
