import React from 'react';
import PropTypes, { InferProps } from "prop-types";

import { Avatar } from '@mui/material';
import {
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot
} from '@mui/lab/';

import './Designation.css';
import { get_month_year } from '../../../../../utils/date';

function render_period(start_date: Date, end_date: Date | null | undefined) {
    return (
        <span>{get_month_year(start_date)} to {end_date ? get_month_year(end_date) : "Present"}</span>
    )
}

export function Designation(props: InferProps<typeof Designation.propTypes>) {
    return (
        <TimelineItem position='left'>
            <TimelineSeparator>
                <TimelineDot color="warning" />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <div className='designation'>
                    <div className='primary'>
                        <h3>{props.title}</h3>
                        <p>{props.org.title}</p>
                        <p className='period'>{render_period(props.start_date, props.end_date)}</p>
                    </div>
                    <Avatar className='icon' alt={props.org.title} src={props.org.icon} variant="square" />
                </div>
            </TimelineContent>
        </TimelineItem>
    )
}

Designation.propTypes = {
    start_date: PropTypes.instanceOf(Date).isRequired,
    end_date: PropTypes.instanceOf(Date),
    org: PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
}
