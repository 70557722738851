import React from 'react';
import { InferProps } from "prop-types";

import { Typography } from '@mui/material';
import { Timeline } from '@mui/lab/';

import { Project } from './Project/Project';
import { Designation } from './Designation/Designation';

import './Experience.css'

import { experience } from '../../../../data';
export function Experience(props: InferProps<typeof Experience.propTypes>) {
    return (
        <div className="experience">
            <Typography variant="h4" component="h2" className="title">
                Experience
            </Typography>
            <Timeline>
                {
                    experience.map(item => {
                        return (
                            item.type === "designation" ?
                                <Designation
                                    start_date={item.start_date!}
                                    end_date={item.end_date}
                                    org={item.org}
                                    title={item.title}
                                /> :
                                <Project
                                    title={item.title}
                                    goal={item.goal!}
                                    personal_contribution={item.personal_contribution!}
                                    tech_stack={item.tech_stack!}
                                    org={item.org!}
                                />
                        )
                    })
                }
            </Timeline>
        </div>
    )
}

Experience.propTypes = {}
