import React from 'react';
import { InferProps } from "prop-types";

import { Grid } from '@mui/material';

import BusinessIcon from '@mui/icons-material/Business';
import SchoolIcon from '@mui/icons-material/School';

import './Introduction.css'

export function Introduction(props: InferProps<typeof Introduction.propTypes>) {
    return (
        <Grid container spacing={2} className="introduction">
            <Grid item xs={12} md={4} className="photo">
                <img alt="Rahul Kumar" src="img/rahul.jpeg" />
            </Grid>
            <Grid item xs={12} md={4} className="heading">
                <h1 className="name">Rahul Kumar</h1>
                <h2 className="desig">Architect, MLOps Engineer</h2>
                <p>
                    I have worked with multiple client in South East Asia, Europe and
                    North America and helped them in developing platform for analytics
                    solution on cloud and on premises.
                    <br />
                    <br />
                    Apart from developing analytics solution, I also work on building
                    data engineering and optimizing data science pipelines.
                </p>
            </Grid>
            <Grid item xs={12} md={4} className="company">
                <div className="org">
                    <BusinessIcon />
                    <div>
                        <p>Architect, Tech Delivery</p>
                        <p>QuantumBlack, AI by McKinsey</p>
                    </div>
                </div>
                <div className="edu">
                    <SchoolIcon />
                    <div>
                        <p>Computer Science & Engineering</p>
                        <p>BMS College of Engineering</p>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

Introduction.propTypes = {}
