import React, { Fragment } from 'react';
import { InferProps } from "prop-types";

import { Introduction } from './Introduction/Introduction';
import { Skills } from './Skills/Skills';
import { Experience } from './Experience/Experience';
import { Certification } from './Certification/Certification';

import './Body.css'

export function Body(props: InferProps<typeof Body.propTypes>) {
    return (
        <Fragment>
            <Introduction />
            <Skills />
            <Experience />
            <Certification />
        </Fragment>
    )
}

Body.propTypes = {}
