export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];


export const get_month_year = (date: Date) => {
    return `${months[date.getMonth()].substring(0, 3)} ${date.getFullYear()}`;
}