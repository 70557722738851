import React from 'react';

import { Homepage } from './Components/Homepage/Homepage'

function App() {
  return (
    <Homepage />
  );
}

export default App;
